<template>
  <div class="flex-item flex-1">
    <!-- 
    <div class="card push-bottom fx fx-slide-up fx-delay-3">
      <div  class="text-secondary">
        <span class="text-faded">Playlist:</span> {{ PochetteEnCours }}
      </div>
    </div>-->

    <div class="push-bottom ">
      <div class="flex-item flex-top flex-stretch fx fx-slide-up fx-delay-4 flex-1">
        <div id="album">
		<div id="cover" :style="{'background-image':'url(' + PochetteEnCours + ')'}">
			<div id="print">
			</div>
		</div>
		<div id="vinyl">
			<div id="print">
			</div>
		</div>
	</div>

        <div class="pad-bottom current-song" style="margin-top: 4%">
          <hr style="height:2px;border-width:0;color:white;background-color:white">
          <h3 v-if="TitreEnCours" style="color: #fff;text-transform: capitalize;font-size: none;">{{ TitreEnCours }}</h3>
          <h3 v-else style="color: #fff;text-transform: capitalize;">{{ config.title }}</h3>
          <h5 v-if="ArtisteEnCours" style="color: #fff;text-transform: capitalize;font-size: none;">{{ ArtisteEnCours }}</h5>
          <h5 v-else class="text-faded" style="color: #fff;text-transform: capitalize;">{{ config.description }}</h5>
          <hr style="height:2px;border-width:0;color:white;background-color:white">
        </div>

      </div>
      <div v-if="NextTitre" class="next-song" style="margin-top: -3%">
        <div class="fx flex-row flex-middle flex-space fx-slide-left fx-delay-2">
          <div class="pad-right" style="color: #fff;text-transform: capitalize;"> 
            <span style="opacity: .8">Prochain Titre à {{ formatDate(NextHeure) }} :</span>
            <h5 style="opacity: .8;color: #fff;text-transform: capitalize;">{{ NextTitre }} - {{ NextArtiste }}</h5><br>
          </div>
          <div class="pad-left"><img style="opacity: .8" :alt="NextTitre" :src="NextPochette"
                                     height="55"
                                     width="55"/></div>
        </div>
      </div>
    </div>
    <!-- buttons 
    <div v-if="config.socialBtn" class="push-bottom text-nowrap">
      <router-link to="/dedicace" class="cta-btn text-nowrap fx fx-slide-up fx-delay-5"  custom v-slot="{ navigate }" >
                        <span class="fx fx-notx fx-ibk fx-drop-in" @click="navigate" @keypress.enter="navigate"><i
                                        class="fa fa-comments"></i></span>
                      </router-link>
      &nbsp;
      <a v-if="currentsong" :href="twitterShare" class="cta-btn text-nowrap fx fx-slide-up fx-delay-6"
         rel="noreferrer" target="_blank" title="Share on twitter">
        <i class="fab fa-twitter"></i>
      </a> &nbsp;
    </div>-->

  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import favBtn from "@/views/components/favBtn";


export default {
  name: "mainSong",
  components: {
    favBtn
  },
          data: () => {
            return {
                NextArtiste: "",
                NextTitre: "",
                NextPochette : "/img/icon.png",
                NextHeure : "",
            }
        },
  methods: {
    formatDate(dateString) {
    const options = { hour: '2-digit', minute: '2-digit' };
    const dateObj = new Date(dateString);
    return dateObj.toLocaleTimeString([], options);
  },
          test2() {
    axios.get(this.config.api_url)
    .then(response => {
    if (response.data.next_song[0].title !== this.NextTitre) {
          this.NextArtiste = response.data.next_song[0].artist;
          this.NextTitre = response.data.next_song[0].title;
          this.NextHeure = response.data.next_song[0].started_at;
          if(response.data.next_song[0].cover_url) {
            this.NextPochette = response.data.next_song[0].cover_url;
          }   else {
             this.NextPochette = "/img/icon.png" ;
          }
    }
    console.log(response.data[0]);
    })
    setTimeout(this.test2, 12000); 
},

  },

    props: {
    ArtisteEnCours: String,
    TitreEnCours: String,
    PochetteEnCours: String,
  },
  computed: {
    config(){
      return this.$store.getters["playerConfig/getConfig"];
    },
    hasNextSong() {
      if (this.nextSong != null) return true;
      return false;
    },
    twitterShare(){
      return "https://twitter.com/share?text=" + this.$t('tw_listening_to') + this.currentsong.text + this.$t('live_on') + this.config.title + "&url=" + window.location.href ; // + "&hashtags=hashtag1,hashtag2,hashtag3"
    },
  },
          mounted() {

    this.test2();
        },
}
</script>

<style scoped>

</style>